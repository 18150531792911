const PROVIDER_COLORS: { [provider: string]: string } = {
    Hertz: '#FFFF00',
    Dollar: '#FF0000',
    Avis: '#C00000',
    Sixt: '#ED7D31',
    Europcar: '#00B050',
    Rentalcars: '#0085ff',
    Budget: '#F4B083',
    Virtuo: '#252525',
    Lyft: '#8B37FF',
    Booking: '#003580',
    Expedia: '#343B53',
    Alamo: '#1F3864',
    National: '#00703C',
    SurPrice: '#C6000D',
    easirent: '#EF3437',
    Thrifty: '#0070C0',
    'Arnold Clark': 'rgba(26,43,39,0.54)',
    'Keddy By Europcar': '#CCFF99',
    Firefly: '#666699',
    Enterprise: '#385623',
    Greenmotion: '#6AA601',
    'THE OUT': '#000000',
    Hotwire: '#db3939',
    Kayak: '#ff690f',
    Priceline: '#0068EF',
    Keddy: '#CCFF99',
    Locauto: '#8EAADB',
    Maggiore: '#00B0F0',
    Buchbinder: '#833C0B',
    Flizzr: '#FFE598',
    Goldcar: '#548135',
    Centauro: '#BF9000',
    RecordGo: '#FA7572',
    goldcar: '#548135',
    Rentacar: '#C51861',
};

export default PROVIDER_COLORS;
